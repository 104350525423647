.creative-page section{
    padding:1rem;
    align-items: center;
    margin-bottom: 1rem;
}

.creative-page p,
.creative-page h3,
.creative-page h2{
    text-align: center;
}

.creative-page section a{
    display:flex;
    justify-content: center;
}

.creative-page .section-odd{
    background-color: var(--hl-color-1);
    border-radius: 1rem;
}

.creative-page .section-even{
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;
}


.creative-page .content-area img{
    width: 100%;
}