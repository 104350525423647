.web-dev-page section{
    padding:1rem;
    align-items: center;
    margin-bottom: 1rem;
}

.web-dev-page p,
.web-dev-page h3,
.web-dev-page h2{
    text-align: center;
}

.web-dev-page .secondary-link-style h3{
    margin: 0;
}


.web-dev-page .section-odd{
    background-color: var(--hl-color-1);
    border-radius: 1rem;
}

.web-dev-page .section-even{
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;
}


.web-dev-page .content-area img{
    width: 100%;
}

.web-dev-page .link-eque-button{
    margin: .5rem 0;
    width: unset;
}

.web-dev-page .content-area iframe {
    width: calc(100vw - 5rem);
    height: calc((2/3) * (100vw - 5rem));
    min-width: 240px;
    min-height: calc((2/3) * 240px);
    max-width: 700px;
    max-height: calc((2/3)*700px);
}