.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page img {
    width: 100%;
    border-radius: 1rem;
}

.about-page .links {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
}

.about-page .links a{
    width:100%;
}

.about-page .section-even {
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;
    width: 50%;
    min-width: 240px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.about-page .section-odd {
    background-color: var(--hl-color-1);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.about-page .section-odd p {
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .about-page .section-even {
       width:100%;
    }
 }