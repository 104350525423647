@keyframes example {
    from{top: 100vh}
    to {top: 0vh}
}
.splash-screen{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color:var(--second-light-color);
    z-index: var(--z-splash-screen);
    min-width: 300px;
}

.splash-screen-content{
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: var(--main-light-color);
    justify-content: center;
    align-items: center;
    animation-name: example;
    animation-duration: 2s;
}

.splash-screen h1{
    margin: 0;
    width: 100%;
    text-align: center;
}