.bike-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bike-page h2,
.bike-page h3,
.bike-page p {
    text-align: center;
}

.bike-page .section-even {
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;
    width: 50%;
    min-width: 240px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.bike-page .section-odd {
    background-color: var(--hl-color-1);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.bike-page .bike-lindsay-img {
    width: 100%;
}

.bike-page .route-link{
    margin: 20px 0px;
    background-color: var(--hl-color-3);
    color: var(--main-light-color);
    padding: unset;
    border-radius: 1rem;
    text-align: center;
    transition: all .2s ease-in-out;
    font-weight: bold;
}

.bike-page .route-link:hover {
    transform: scale(1.02);
  }

.bike-page .route-link a {
    color: var(--main-light-color);
    padding: .5rem;
    display: inline-block;
    width: 100%;
    margin: unset;
}

.bike-page .links {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
}

.bike-page .secondary-link-style {
    width: 100%
}

.bike-page .div-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.bike-page .div-row .secondary-link-style {
    width: fit-content;
    background-color: var(--second-dark-color);
}

.bike-page #playlists a {
    background-color: var(--second-dark-color);
}

.bike-page .ig-video {
    width: 50%;
    min-width: 240px;
}

.bike-page .ig-video video {
    width: 100%;
}