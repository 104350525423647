.blog-post-page {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1rem;
   width: 100%;
}

.blog-post-content * {
   margin-bottom: 1rem;
}

.blog-post-content .important-info *,
.blog-post-content .img-container .img{
   margin-bottom: unset;
}

.blog-post-page h2,
.blog-post-page h3 {
   text-align: center;
}

.blog-post-page .blog-post-content {
   border: 1px solid var(--hl-color-2);
   border-radius: 1rem;
   width: 100%;
   padding: 1rem;
}

.blog-post-page .blog-image {
   max-width: 100%;
   max-height: 510px;
   margin: 1rem 0;
}

.blog-post-page .prerequisites {
   background-color: var(--hl-color-1);
   border-radius: 1rem;
   padding: 1rem;
}

.blog-post-page .important-info {
   background-color: var(--second-dark-color);
   color: var(--second-light-color);
   padding: 1rem;
   display: flex;
}

.blog-post-page .important-info .icon-container {
   display: flex;
   align-items: center;
   padding-right: 1rem;
}

.blog-post-page .important-info .icon {
   width: 20px;
}

.blog-post-page a{
   margin-right: 3px;
}

.blog-post-page .prerequisites ul {
   list-style-type: unset;
   margin-left: 1rem;
   margin-bottom: unset;
}
.blog-post-page .steps{
   width:100%;
}

.blog-post-page .step {
   margin-top: 1rem;
   border-top: 1px solid var(--hl-color-2);
}

.blog-post-page .step h4 {
   color: var(--second-dark-color);
}

.blog-post-page .step-body {
   margin: 0 1rem 0 1rem;
}

.blog-post-page code {
   width: 100%;
   
   display: block;
   overflow: auto;
   color: var(--hl-color-3);
   background-color: var(--main-dark-color);
   padding: 1rem;
}

.blog-post-page .img-container {
   width: 100%;
   display: flex;
   justify-content: center;
}

.blog-post-page .img-container img {
   border: 1px solid var(--hl-color-2);
   border-radius: 1rem;
   max-width: 100%;
   max-height: 450px;
   padding: 1rem;
}

.blog-post-page .prerequisites li{
   margin-bottom: unset;
}