.contact-page {
   display: flex;
   flex-direction: column;
   align-items: center
}

.contact-page .section-even {
   border: 1px solid var(--hl-color-2);
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   padding: 1rem;
}

.contact-page .section-even a{
   width: 300px;
}
