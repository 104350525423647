.app {
    min-width: 300px;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

header {
    /* background-color: pink; */
    /* height: 80px; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    position: sticky;
    top: 0px;
    background-color: var(--main-light-color);
    z-index: var(--z-header);
    border-bottom: 1px solid var(--second-light-color);
}

header h1 {
    width: 100%;
    text-align: center;
}


nav {
    width: fit-content
}

nav ul {
    justify-content: center;
}

nav li {
    margin: 0px 10px
}

main {
    width: 100%;
    max-width: 800px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: var(--z-main);
}

.app .test-div {
    height: 1000px;
    width: calc(100% - 40px);
    background-color: lightgrey;
    padding: 20px;
}