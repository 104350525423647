.tile {
    background-color: var(--second-light-color);
    border-bottom: 1px solid var(--second-light-color);
    overflow: hidden;
    width: 100%;
    height: calc((2/3) * 100vw);
}

.tile-title {
    width: 100%;
    padding-left: 1rem;
    position: absolute;
    transition: all .2s ease-in-out;
    background: var(--main-light-color);
    top: 0px;
    margin: 0;
    color: var(--main-dark-color)
}

@media only screen and (min-width: 800px){
    .tile{
        height: calc((2/3) * 800px)
    }
}

@media only screen and (max-width: 310px){
    .tile{
        height: calc((2/3) * 270px)
    }
}

.tile-title:hover {
    transform: scale(1.02);
}


.tile a:hover {
    color: unset;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
}

.content>picture,
.content>picture>img {
    width:100%;
}

.content>picture{
    display: flex;
    height: 100%;
}

.content>picture>img{
    margin-top: 37px;
}

.content>video {
    height: 100%;
}