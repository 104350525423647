* {
  box-sizing: border-box;
}

:root {
  --main-light-color: #ffffff;
  --second-light-color: #dbd9d9;
  --main-dark-color: #293241;
  --second-dark-color: #3d5a80;
  --hl-color-1: #fdd74c;
  --hl-color-2: #35bbef;
  --hl-color-3: #ee6c4d;
  --z-splash-screen: 3;
  --z-header: 2;
  --z-title: 1;
  --z-main: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-light-color);
  color: var(--main-dark-color);

}

.secondary-font {
  font-family: source-sans-pro, sans-serif;
}

/* https://css-tricks.com/rem-global-em-local/ */

html {
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: var(--hl-color-1);
  border: none;
  color: var(--main-dark-color);
  padding: .5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  width: 300px;
}

input{
  width: 300px;
  height: 30px;
  border-radius: 1rem;
  background-color: var(--main-light-color);
  padding:5px;
}

h2,
h3,
h4,
h5,
h6,
ul,
p,
a {
  margin: .5rem 0;
}


ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--hl-color-2);
  width: fit-content;
}

a:hover {
  color: var(--second-dark-color);
}

.secondary-link-style {
  background-color: var(--hl-color-3);
  color: var(--main-light-color);
  padding: .5rem;
  border-radius: 1rem;
  text-align: center;
  transition: all .2s ease-in-out;
  font-weight: bold;
}

.error-text{
  color: red;
  font-weight: bold;
}

.secondary-link-style:hover {
  color: var(--main-light-color);
  transform: scale(1.02);
}

.page-content {
  padding: 1rem;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.underline {
  text-decoration: underline;
  text-decoration-color: var(--hl-color-2);
}

.underline:hover {
  text-decoration-color: var(--main-dark-color);
}

.fade-in {
  opacity: 0;
  animation-name: fadein;
  animation-delay: 1s;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}