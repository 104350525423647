.blog-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-page a {
    margin: 0;
    width: 100%;
    color: var(--main-dark-color)
}

.blog-page a:hover {
    color: unset;
}

.blog-page section {
    padding: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    transition: all .2s ease-in-out;
}



.blog-page section:hover {
    transform: scale(1.02);
}

.blog-page section .text-area {
    flex: 1;
}

.blog-page .highlighted-blog {
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;

}

.blog-page .highlighted-blog img {
    width: 33vw;
    min-width: 50%;
    max-width: 50%;
}

.blog-page .section-odd {
    background-color: var(--hl-color-1);
    border-radius: 1rem;
}

.blog-page .section-even {
    border: 1px solid var(--hl-color-2);
    border-radius: 1rem;
}

.blog-page .section-odd img,
.blog-page .section-even img {
    height: 100px;
}

@media only screen and (max-width: 500px) {
    .blog-page p,
    .blog-page h2,
    .blog-page h3 {
        text-align: center;
    }

    .blog-page section {
        flex-direction: column;
    }

    .blog-page .highlighted-blog img {
        width: unset;
        max-width: 100%;
    }
}